<template>
  <ListPage :gridData="data" :gridConfig="gridConfig" :filters="filters" />
</template>

<script>
import ListPage from "@/components/penal-retail-helpers/ListPage";
import { db } from "@/firebase/firebaseConfig";

import lawyersFormConfig from "@/helpers/lawyersFormConfig";

let collectionName = "lawyers";

export default {
  components: {
    ListPage,
  },
  data() {
    let attributes = lawyersFormConfig;

    let columns = [];
    ["dni", "alias", "lawyerNumber"].forEach((attribute) => {
      columns.push({
        headerName: this.$t(`${collectionName}.columns.${attribute}`),
        filter: false,
        width: 100,
        field: attribute,
      });
    });

    let filters = [];
    filters.push({ size: "1/5", mobileSize: "1/2", search: true });
    filters.push({
      size: "4/5",
      mobileSize: "1/2",
      sidebar: true,
      i18n: `${collectionName}.buttons.new`,
      extraClasses: "text-right",
      sidebarConfig: {
        title: `${collectionName}.buttons.new`,
        collectionName: collectionName,
        attributes: attributes,
        prevalidate: async (data, collection, errors) => {
          let snapshot = await collection.where("dni", "==", data.dni).get();

          if (snapshot.docs && snapshot.docs.length > 0) {
            errors.add({
              field: "dni",
              msg: this.$t(`Validations.${collectionName}.dni.unique`),
            });
            return true;
          }

          return false;
        },
      },
    });

    return {
      data: [],
      filters,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  created() {
    this.$bind(
      "data",
      db
        .collection(collectionName)
        .where("deleted", "==", false)
        .orderBy("alias"),
      { wait: true }
    );
  },
  methods: {
    onRowClicked(params) {
      this.$router
        .push(`/settings/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
