import states from "@/data/generic/states";

let fields = [];
fields.push({ size: "1/2", type: "input", attribute: "dni", validation: "required" });
fields.push({ size: "1/2", type: "input", attribute: "lawyerNumber", validation: "required" });
fields.push({ size: "1/2", type: "input", attribute: "alias", validation: "required" });
fields.push({ size: "1/2", type: "input", attribute: "email", validation: "email" });
fields.push({ size: "1/2", type: "input", attribute: "phone", validation: "digits:9" });
fields.push({ size: "1/2", type: "select", attribute: "state", 
    extra: {
        options: states.map(s => (Object.assign({ id: `${s.alias}_${s.countryId}`}, s))).sort((a, b) => { return a.alias.localeCompare(b.alias);})
    }
});
fields.push({ size: "1/2", type: "color", attribute: "color" });

export default fields;